import type {Action} from '~/flux/ActionTypes';
import {Logger} from '~/lib/Logger';

const logger = new Logger('Dispatcher');

class Dispatcher {
	private static instance: Dispatcher;
	private listeners: Array<(action: Action) => void> = [];
	private isDispatching = false;
	private actionQueue: Array<Action> = [];

	static getInstance(): Dispatcher {
		if (!Dispatcher.instance) {
			Dispatcher.instance = new Dispatcher();
		}
		return Dispatcher.instance;
	}

	register(listener: (action: Action) => void): void {
		this.listeners.push(listener);
	}

	dispatch(action: Action): void {
		if (this.isDispatching) {
			this.actionQueue.push(action);
			return;
		}

		this.isDispatching = true;
		if (process.env.NODE_ENV === 'development') {
			logger.debug(`Dispatching action: ${action.type}`);
		}

		const startTime = Date.now();
		try {
			for (const listener of this.listeners) {
				listener(action);
			}
		} finally {
			this.isDispatching = false;
			const endTime = Date.now();
			const timeTaken = endTime - startTime;
			if (timeTaken > 100) {
				logger.warn(`Slow dispatch: ${action.type} took ${timeTaken}ms`);
			}
			this.processQueue();
		}
	}

	private processQueue(): void {
		while (this.actionQueue.length > 0) {
			const queuedActions = [...this.actionQueue];
			this.actionQueue = [];
			for (const nextAction of queuedActions) {
				if (process.env.NODE_ENV === 'development') {
					logger.debug(`Dispatching queued action: ${nextAction.type}`);
				}
				this.dispatch(nextAction);
			}
		}
	}
}

export default Dispatcher.getInstance();
