import IntlMessageFormat from 'intl-messageformat';
import languageEn from '~/i18n/languages/en.json';

const formatCache = new Map<string, IntlMessageFormat>();

const getFormatter = (message: string): IntlMessageFormat => {
	if (!formatCache.has(message)) {
		formatCache.set(message, new IntlMessageFormat(message));
	}
	return formatCache.get(message)!;
};

type FormatValues = Record<string, any>;
type Format = (message: string, values?: FormatValues) => any;

const createI18n = (): {Messages: typeof languageEn; format: Format} => ({
	Messages: languageEn,
	format(message, values): any {
		return getFormatter(message).format(values);
	},
});

export const i18n = createI18n();
