import {useNavigate} from '@remix-run/react';
import EventEmitter from 'eventemitter3';
import React from 'react';

type NavigateEventPayload = {
	path: string;
	replace: boolean;
};

type NavigateEvents = {
	navigate: (payload: NavigateEventPayload) => void;
};

export const NAVIGATE_EVENT_BUS = new EventEmitter<NavigateEvents>();

export const NavigateListener = () => {
	const navigate = useNavigate();

	React.useEffect(() => {
		const handleNavigation: NavigateEvents['navigate'] = ({path, replace}) => {
			if (replace) {
				navigate(path, {replace: true});
			} else {
				navigate(path);
			}
		};

		NAVIGATE_EVENT_BUS.on('navigate', handleNavigation);
		return () => {
			NAVIGATE_EVENT_BUS.off('navigate', handleNavigation);
		};
	}, [navigate]);

	return null;
};
